import React, { Fragment, useLayoutEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { uiActions } from '../../store/ui';
import { StoreState } from '../../store';
import { SectionNav, SectionNavProps } from '../partials';

export interface BaseTemplateProps {
  navTitle?: SectionNavProps['title'];
  navLinks?: SectionNavProps['links'];
}

export const BaseTemplate: React.FC<BaseTemplateProps> = (props) => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state: StoreState) => {
    return state.ui.showInfoBar;
  });

  useLayoutEffect(() => {
    dispatch(uiActions.showHeader(true));
  }, [])

  function handleTogleInfo() {
    dispatch(uiActions.showInfoBar(!sidebarOpen));
  }

  return (
    <Fragment>
      <div className={cn("content", {'-sidebar-open': sidebarOpen})}>
        {/* {!sidebarOpen &&
          <Tooltip content="Infomation Panel" position="left" hoverOpenDelay={1000} intent="info">
            <div className="opener">
              <div
                className="close -fill -lg -default -circular"
                onClick={handleTogleInfo}
                children={sidebarOpen ? <IconX /> : <IconInfoCircle />}
              />
            </div>
          </Tooltip>
        } */}
        <SectionNav title={props.navTitle} links={props.navLinks ?? []} />
        {props.children}
      </div>
      {/* <InformationBar /> */}
    </Fragment>
  )
}
