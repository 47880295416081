import React, { Fragment, useMemo, useState } from 'react';
import { format as formatDate } from 'date-fns';
import cn from 'classnames';
import { isUndefined } from 'lodash-es';
import { HomeSite } from '@adair/core-client-utilities/lib/home-site';
import { Document } from '@adair/core-client-utilities/lib/document';
import {
  Container, Columns, Column,
  Box,
  Button, Buttons,
  Select, GenericOption,
  toaster,
  marginClass,
  Pane,
  Heading,
  ObjectTable,
  sortObjects,
} from '@adair/core-ui';
import {
  IconDownload,
  IconViewGrid,
  IconViewList
} from '@adair/core-ui/lib/icons';

import { Error404Page } from '../error/error-404';
import { api } from '../../../util/api';
import { DocumentTile, SEO } from '../../partials';
import { getHomeSiteName } from './home-site-index';

interface HomeDashPageProps {
  children?: never;
  homeSite?: HomeSite;
}

const filterOptions: GenericOption<string>[] = [
  { label: 'All', value: 'all' },
  { label: 'Quote Binder', value: 'quote-binder' },
  { label: 'Redlines', value: 'redlines' },
  { label: 'Property Evaluation Report', value: 'property-evaluation-report' },
  { label: 'Plat Map', value: 'plat-map' },
  { label: 'Jurisdiction Checklist', value: 'jurisdiction-checklist' },
  { label: 'Floor Plan', value: 'floor-plan' },
  { label: 'Earnest Money Agreement', value: 'earnest-money-agreement' },
  { label: 'Financial Prequalification', value: 'Financial Prequalification' },
  { label: 'Financial Preapproval', value: 'Financial Preapproval' },
  { label: 'Agreement Documents', value: 'agreement-documents' },
  { label: 'Payment Agreement', value: 'payment-agreement' },
  { label: 'Order Binder', value: 'order-binder' },
  { label: 'Basement Addendum', value: 'basement-addendum' },
  { label: 'Price Protection Letter', value: 'price-protection-letter' },
  { label: 'Customer Packet', value: 'customer-packet' },
];

const sortOptions: GenericOption<string>[] = [
  { label: 'Name', value: 'name' },
  { label: 'Date', value: 'date' },
];

export const HomeDocumentsPage: React.FC<HomeDashPageProps> = (props) => {
  const [isList, setIsList] = useState(false);
  const [filter, setFilter] = useState(filterOptions.slice(0, 1));
  const [sort, setSort] = useState(sortOptions.slice(0, 1));

  function handleFilterReset() {
    setFilter(filterOptions.slice(0, 1));
    setSort(sortOptions.slice(0, 1));
  }

  function downloadDocument(document: Document) {
    api.document.download(document.id)
      .catch((err) => {
        toaster.danger({
          heading: 'There was an error downloading your document',
          text: 'If the issue persists, please contact us for assistance.',
        })
      })
  }

  function viewDocument(document: Document) {
    api.document.view(document.id)
      .catch((err) => {
        toaster.danger({
          heading: 'There was an error viewing your document',
          text: 'If the issue persists, please contact us for assistance.',
        })
      })
  }

  const displayDocuments = useMemo(() => {
    const filteredDocs = props.homeSite?.documents.filter((doc) => {
      if (filter[0].value === 'all') return true;
      return doc.documentType === filter[0].value;
    }) ?? [];
    let sortedDocs = filteredDocs;
    switch (sort[0].value) {
      case 'name':
        sortedDocs = sortObjects(filteredDocs, 'title')
        break;
      case 'date':
        sortedDocs = sortObjects(filteredDocs, 'lastModifiedDate', ['desc'])
        break;
    }
    return sortedDocs;
  }, [props.homeSite?.documents, filter, sort]);

  if (isUndefined(props.homeSite)) return <Error404Page />;

  function renderDocumentFilters() {
    return (
      <Container margin={[6, null]} >
        <Columns align="bottom">
          <Column>
            <Select
              field="page-filter"
              activeOptions={filter}
              before={<Pane as="strong" margin={[null, null, null, 2]}>Filter:</Pane>}
              isInline
              options={filterOptions}
              renderLabel={'label'}
              renderOption={'label'}
              optionIdProp={'value'}
              className={marginClass([null, 2, null, null])}
              onActiveChange={setFilter}
            />
            <Select
              field="page-sort"
              activeOptions={sort}
              before={<Pane as="strong" margin={[null, null, null, 2]}>Sort:</Pane>}
              isInline
              options={sortOptions}
              renderLabel={'label'}
              renderOption={'label'}
              optionIdProp={'value'}
              onActiveChange={setSort}
            />
          </Column>
          <Column span="shrink">
            <Buttons appearance="outline" isCombined>
              <Button label={<IconViewList />} isSquare isActive={isList} onClick={() => setIsList(true)} />
              <Button label={<IconViewGrid />} isSquare isActive={!isList} onClick={() => setIsList(false)} />
            </Buttons>
          </Column>
        </Columns>
      </Container>
    )
  }

  function renderDocumentGrid(documents: Document[]) {
    return (
      <Columns>
        {displayDocuments.map((document) => {
          return (
            <Column span={{ xs: 12, md: 6 }} key={document.id}>
              <DocumentTile
                bgColor="gray100"
                document={document}
              />
            </Column>
          )
        })}
      </Columns>
    )
  }

  function renderDocumentList(documents: Document[]) {
    return (
      <ObjectTable<Document>
        items={displayDocuments} idProp="id"
        divide="horizontal"
        density="relaxed"
        isBordered
      >
        <ObjectTable.Column<Document>
          id="name"
          header="Name"
          renderCell={(doc) => <Heading size={1}>{doc.displayName}</Heading>}
        />
        <ObjectTable.Column<Document>
          id="date"
          header="Last Update"
          renderCell={(doc) => formatDate(doc.createdDate, 'LLL d, yyyy')}
          width="140px"
        />
        <ObjectTable.Column<Document>
          id="download"
          width="140px"
          renderCell={(doc) => (
            <Buttons size="small" density="compact" flexJustify="end">
              <Button
                label="view"
                appearance="outline"
                onClick={() => viewDocument(doc)}
              />
              <Button
                label={<IconDownload size="small" />}
                isSquare
                intent="primary"
                onClick={() => downloadDocument(doc)}
              />
            </Buttons>
          )}
        />
      </ObjectTable>
    )
  }

  if (props.homeSite.documents.length === 0) {
    return (
      <Fragment>
        {renderDocumentFilters()}
        <Container maxWidth="md" margin={[8, null]}>
          <Box density="relaxed">
            <Box.Body className="t--center">
              <Heading size={2}>There are no documents yet...</Heading>
              <div>Documents are added as your home progresses, so check back.</div>
            </Box.Body>
          </Box>
        </Container>
      </Fragment>
    )
  }

  if (displayDocuments.length === 0) {
    return (
      <Fragment>
        {renderDocumentFilters()}
        <Container maxWidth="md" margin={[8, null]}>
          <Box density="relaxed">
            <Box.Body className="t--center">
              <Heading size={2} margin={[null, null, 4]}>No documents match the current criteria...</Heading>
              <Buttons flexJustify="center">
                <Button label="Reset Filters" size="small" intent="primary" onClick={handleFilterReset} />
              </Buttons>
            </Box.Body>
          </Box>
        </Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {renderDocumentFilters()}
      <Pane as="section" padding={[4, null, 8]}>
        <SEO title={`${getHomeSiteName(props.homeSite)} | Documents`} />
        <Container maxWidth="lg">
          {isList
            ? renderDocumentList(displayDocuments)
            : renderDocumentGrid(displayDocuments)
          }
        </Container>
      </Pane>
    </Fragment>
  );
};
