import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import App from './app';
import { DocHead } from './layout/partials';

ReactDOM.render(
  <Provider store={store}>
    <DocHead
      defaultTitle="Welcome"
      defaultDescription="A resource to help design, build, and maintain your Adair Home"
      titleTemplate={"{{title}} | MyAdair"}
    />
    <App />
  </Provider>
, document.getElementById('root'));
