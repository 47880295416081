import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Heading, marginClass, Tag } from '@adair/core-ui';

import { uiActions } from '../../store/ui';

export const SplitTemplate: React.FC = (props) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(uiActions.showHeader(false));
  }, [])


  return (
    <div className="split-layout">
      <div
        className="sidebar"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_S3_URL}/sidebar-bg.jpg)`,
        }}
      >
        <div className="sidebar__overlay">
          <div className="logo">
            <img src="/my-adair-logo-inverse.svg" height="32px" />
            <Tag
              shape="circular"
              size="small"
              intent="warning"
              className={marginClass([null, null, null, 2])}
              label="Beta"
            />
          </div>
          {/* <div className="sidebar__overlay-text">
            <div className="f--3 c--white">A set of tools to</div>
            <Heading size={6} className="c--white">
              Design, Manage, and Maintain your Adair Home
            </Heading>
          </div> */}
        </div>
      </div>
      <div className="main-content">
        <div className="logo">
          <img src="/my-adair-logo.svg" height="32px" />
          <Tag
            shape="circular"
            className={marginClass([null, null, null, 2])}
            label="Beta"
            size="small"
          />
        </div>
        <div className="main-content__interior">
          {props.children}
        </div>
      </div>
    </div>
  )
}
