import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Box,
  Form, Input,
  Button,
  DevelopmentSelector,
  Dialog,
  Heading,
} from '@adair/core-ui';
import { CustomerProfile } from '@adair/core-client-utilities/lib/customer-profile';

import { StoreState, useThunkDispatch } from '../../../store';
import { userActions } from '../../../store/user';
import { catchUnauthorized } from '../../../util/catch-401';
import { config } from '../../../config';

export const SettingsPage: React.FC = () => {
  const dispatch = useThunkDispatch();
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const settings = useSelector((state: StoreState) => {
    return state.user.customerProfile?.settings;
  })

  function renderDevelopmentSelector() {
    return (
      <Dialog
        isOpen={selectorIsOpen}
        size="sm"
        onClose={() => setSelectorIsOpen(false)}
      >
        <DevelopmentSelector
          apiToken={config.mapbox.token}
          username={config.mapbox.username}
          stateDatasetId={config.mapbox.stateDatasetId}
          regionDatasetId={config.mapbox.regionDatasetId}
          onComplete={(selection) => {
            // set development
            const location: CustomerProfile['settings']['location'] = {
              companyCode: selection.region?.properties?.COMPANY,
              developmentCode: selection.region?.properties?.DEVELOPMENT,
            }
            dispatch(userActions.updateCustomerProfileSettings.action({ location }))
              .catch(catchUnauthorized)
              .catch(() => {})

            // close dialog
            setSelectorIsOpen(false);
          }}
        />
      </Dialog>
    )
  }

  return (
    <Container maxWidth="md" margin={[8, null]}>
      <Box density="relaxed">
        <Box.Header>
          <Heading size={2}>Settings</Heading>
        </Box.Header>
        <Box.Body>
          <div>
            <Form.Field
              label="Default Build Area"
              description="When browsing the Adair website this will be your default build area"
              descriptionPosition="top"
            >
              <Form.Controls isCombined>
                <Form.Control>
                  <Input
                    field="location"
                    value={settings?.locationDevelopment ? settings.locationDevelopment.name : 'Not Set'}
                    readOnly
                  />
                </Form.Control>
                <Form.Control span="shrink">
                  <Button label="Change" intent="primary" onClick={() => setSelectorIsOpen(true)} />
                </Form.Control>
              </Form.Controls>
            </Form.Field>
          </div>
        </Box.Body>
      </Box>
      {renderDevelopmentSelector()}
    </Container>
  )
}
