import React, { useState, useEffect, Fragment } from 'react';
import cn from 'classnames';
import { groupBy } from 'lodash-es';
import useSWR from 'swr';
import { Favorite } from '@adair/core-client-utilities/lib/favorite';
import { Plan } from '@adair/core-client-utilities/lib/plan/types';
import {
  Container, Columns, Column,
  Box,
  Buttons,
  Heading,
  Loader,
  isDefined,
  Message,
} from '@adair/core-ui';

import { LinkButton } from '../../../components';
import { api } from '../../../util/api';
import { handleUnauthorized } from '../../../util/catch-401';
import { PageLoadError } from '../../partials/error/page-load-error';
import { useUser } from '../../../hooks';

interface FavoritesDashProps {
  children?: never;
}

export const FavoritesDashPage: React.FC<FavoritesDashProps> = (props) => {
  const user = useUser();
  const { data, isValidating, error } = useSWR(['favorites', user?.id], async () => {
    return (await api.favorite.fetchAll().request).data;
  });
  const [groupedFavorites, setGroupedFavorites] = useState<Record<string, Favorite<Plan>[]>>({});

  useEffect(() => {
    if (data) {
      setGroupedFavorites(groupBy<Favorite<Plan>>(data, 'modelType'));
    }
  }, [data])
  
  useEffect(() => {
    if (isDefined(error)) {
      handleUnauthorized(error)
    }
  }, [error])

  function renderPage() {
    return (
      <Container maxWidth="lg" padding={[8, null]}>
        <Columns>
          <Column span={{ xs: 12, md: 6 }}>
            <Box appearance="fill" density="relaxed">
              <Box.Body padding={[9, null]} className={cn('t--center')}>
                <Heading size={3} color={'gray500'}>Images</Heading>
                <Heading size={8} margin={[6, null, 8]}>{groupedFavorites.WebAsset?.length ?? 0}</Heading>
                <Buttons flexJustify="center">
                  <LinkButton
                    href="/favorites/images" className="stretched-link"
                    intent="warning"
                    label="View"
                  />
                </Buttons>
              </Box.Body>
            </Box>
          </Column>
          <Column span={{ xs: 12, md: 6 }}>
            <Box appearance="fill" density="relaxed">
              <Box.Body padding={[9, null]} className={cn('t--center')}>
                <Heading size={3} color={'gray500'}>Home Plans</Heading>
                <Heading size={8} margin={[6, null, 8]}>{groupedFavorites.WebPlan?.length ?? 0}</Heading>
                <Buttons flexJustify="center">
                  <LinkButton
                    href="/favorites/plans" className="stretched-link"
                    intent="primary"
                    label="View"
                  />
                </Buttons>
              </Box.Body>
            </Box>
          </Column>
          <Column span={{ xs: 12, md: 6 }}>
            <Box appearance="fill" density="relaxed">
              <Box.Body padding={[9, null]} className={cn('t--center')}>
                <Heading size={3} color={'gray500'}>Virtual Tours</Heading>
                <Heading size={8} margin={[6, null, 8]}>{groupedFavorites.WebTour?.length ?? 0}</Heading>
                <Buttons flexJustify="center">
                  <LinkButton
                    href="/favorites/tours"
                    className="stretched-link"
                    intent="success"
                    label="View"
                  />
                </Buttons>
              </Box.Body>
            </Box>
          </Column>
        </Columns>
      </Container>
    )
  }

  return (
    <Fragment>
      <Loader isLoading={isValidating} />
      {data && renderPage()}
      {error && error.response?.status !== 401 && (
        <Container padding={[8, null]} maxWidth="lg">
          <PageLoadError />
      </Container>
      )}
    </Fragment>
  )
}
