import React from 'react';

export const ErrorTemplate: React.FC = (props) => {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
    }}>
      {props.children}
    </div>
  )
}
