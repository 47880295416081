export const config = {
    url: process.env.REACT_APP_APP_URL || 'https://my.adairhomes.com',
    port: process.env.PORT,
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost'
    },
    mapbox: {
        token: process.env.REACT_APP_MAPBOX_TOKEN ?? '',
        username: process.env.REACT_APP_MAPBOX_USERNAME ?? 'adairhomes',
        stateDatasetId: process.env.REACT_APP_MAPBOX_STATE_DATA_ID ?? '',
        regionDatasetId: process.env.REACT_APP_MAPBOX_DEV_DATA_ID ?? '',
    }
}
